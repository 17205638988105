import React from "react";

import dayjs from "dayjs";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import AsyncSearch from "../../../molecules/AsyncSearch";
import SuggestionList from "../../../molecules/SuggestionList";
import {
  Person,
  PersonActivity,
  useAddPersonMutation,
  useAssignPersonMutation,
} from "../../../../generated/graphql";
import { usePersonCreateContext } from "../provider";
import { STEPS } from "../reducer";
import usePersonSubscription from "../../../../hooks/usePersonSubscription";

const prepareMatches = (matches: any[]): Person[] => {
  return matches.map(match => {
    const personSubscription = usePersonSubscription(match.id);
    const accountContractEnd = personSubscription?.person?.client?.account?.contractEnd;

    return {
      ...match,
      note: "Telefonnummer stimmt überein",
      accountContractEnd
    }
  });
};

const buildSuggestionNote = (activities: PersonActivity[]): string => {
  let note = "Aktivität";

  const activity = activities.length > 0 ? activities[0] : null;

  if (!activity) {
    return note;
  }
  switch (activity.activityType) {
    case "callIn":
      note = `Telefonat eingehend ${dayjs(activity.start).fromNow()}`;
      break;
    case "callOut":
      note = `Telefonat ausgehend ${dayjs(activity.start).fromNow()}`;
      break;
    case "chat":
      note = `Chat ${dayjs(activity.start).fromNow()}`;
      break;
    case "email":
      note = `E-Mail ${dayjs(activity.start).fromNow()}`;
      break;
    default:
      note = `Aktivität ${dayjs(activity.start).fromNow()}`;
      break;
  }

  return note;
};

const prepareSuggestions = (suggestions: any[]): Person[] => {
  return suggestions.map(suggestion => {
    const note = buildSuggestionNote(suggestion.activities);
    const personSubscription = usePersonSubscription(suggestion.id);
    const accountContractEnd = personSubscription?.person?.client?.account?.contractEnd;

    return { ...suggestion, note, accountContractEnd };
  });
};

const unionSuggestions = (matches: any[], suggestions: any[]): any[] => {
  return prepareSuggestions(suggestions).reduce(
    (acc, val) => {
      if (!acc.find(exist => exist.id === val.id)) {
        acc.push(val);
      }
      return acc;
    },
    [...prepareMatches(matches)],
  );
};

interface IPersonAssignNoMatch {
  setPersonAsAnonymous: (values: { personId: number }) => void;
  updatePerson: (
    input: { personId: number; firstName?: string; lastName?: string },
    push?: boolean,
  ) => Promise<void>;
  afterSubmit: (personId: number, redirect: boolean) => void;
  dataHandler?: (data: any) => void;
  previewAction?: (data: any) => void;
}

const PersonAssignNoMatch = ({
  afterSubmit,
  setPersonAsAnonymous,
  updatePerson,
  dataHandler,
  previewAction
}: IPersonAssignNoMatch) => {
  const { personActivity, refetch, setName, setStep } = usePersonCreateContext();
  const [assignPerson] = useAssignPersonMutation();
  const [addPerson] = useAddPersonMutation();

  const personId = personActivity.person?.id;

  if (!personId) {
    throw Error();
  }

  return (
    <>
      <Box width={1}>
        <Box textAlign="center" my={5}>
          <Typography variant="h1" align="center">
            Wähle jetzt eine Person aus der Vorschlagsliste <br />
            oder benutze die Suche.
          </Typography>
        </Box>
        <AsyncSearch.ClientForCallDialogSearch
          dataHandler={dataHandler}
          previewAction={previewAction}
          placeholder="Suche nach Vorname Nachname"
          onClick={async (personId: number) => {
            await assignPerson({
              variables: {
                id: personActivity.id,
                personId,
              },
            });

            afterSubmit(personId, true);
          }}
          onCreateOption={async (name: string) => {
            const [lastName, ...rest] = name.split(" ").reverse();
            const firstName = rest.reverse().join(" ");
            if (personActivity.person?.temporary) {
              await updatePerson(
                {
                  personId,
                  firstName,
                  lastName,
                },
                false,
              );
            } else {
              const person = await addPerson({
                variables: {
                  person: {
                    firstName,
                    lastName,
                    phones: [
                      {
                        areaCode: personActivity.call?.areaCode,
                        countryCode: personActivity.call?.countryCode,
                        number: personActivity.call?.number,
                      },
                    ],
                  },
                },
              });

              const newPersonId = person.data?.addPerson?.id;
              if (newPersonId) {
                await assignPerson({
                  variables: {
                    id: personActivity.id,
                    personId: newPersonId,
                  },
                });
              }
            }

            refetch();
            setName(name);
            setStep(STEPS.TYPE);
          }}
        />
        <Box my={3}>
          <Typography variant="body2">
            Die Person möchte anonym bleiben.{" "}
            <Link
              tabIndex={0}
              underline="always"
              variant="h4"
              onClick={() => {
                setPersonAsAnonymous({ personId });
              }}
              onKeyDown={(e: any) => {
                if (e.which === 13) {
                  setPersonAsAnonymous({ personId });
                }
              }}
            >
              Erzeuge hier einen Alias.
            </Link>
          </Typography>
        </Box>
        <SuggestionList
          suggestions={unionSuggestions(
            personActivity.matches || [],
            personActivity.suggestions || [],
          )}
          suggestionSelect={async personId => {
            await assignPerson({
              variables: {
                id: personActivity.id,
                personId,
              },
            });

            afterSubmit(personId, true);
            // assignPerson({ personActivityId: personActivity.id, personId });
          }}
        />
      </Box>
      <Box></Box>
    </>
  );
};

export default PersonAssignNoMatch;
